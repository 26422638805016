import React from 'react';
import './CultureAndEcology.scss';

class CultureAndEcology extends React.Component {
	render() {
        return (
            <div className="cultureAndEcology">
                <div className="cultureAndEcologyBanner"></div>
                <h3>Culture and Ecology</h3>
                <div className="mainText">
                    <div className="image cultureAndEcologyImage"></div>
                    <p>At a time when we are facing an unprecedented existential crisis in our relationship to nature, it
                    would seem to be important to better understand the way traditional cultures have successfully
                    meshed with their host environments across the world. They relate to their local ecosystems in
                    many different ways, and it may be useful to take stock of some of these adaptations to help us
                    make well-informed choices for the future
                    <p>The natural environment is a key frame of reference for cultures the world over: Intimate interaction with it enable them to survive and to thrive. Invariably the food that they eat and the building materials and the firewood they use come from close by. And for times immemorial, people have developed ceremonies by which they celebrate the ‘land of their fathers and mothers’ in stories, songs and community events.</p></p>
                </div>
                <h4>Pygmy cultural ecology</h4>
                <p>This is true, first and foremost, of our most ancient ‘contemporary ancestors’; the Pygmy communities who inhabit the rainforests of Central Africa. The little people have been at home in the shelter of trees, a world of flickering light and shade, for maybe 100,000 years. Their whole way of life is about being at peace with the forest, the throbbing heart of their familiar world. That is where they feel safe because they know it inside out. The forest supplies them with all their needs and they feed themselves without much effort as they follow their familiar, seasonal migration routes. Every tree, every animal, every stream and hill has a name. To live in a forest with all its ecological diversity means to know all its secrets.</p>
                <p>What would seem to outsiders as a depressing world of gloom is a very different place to the Pygmies. It is a cool, restful, shady world illuminated by light filtering through the treetops, with occasional forest clearings and streams rich in fish. The forest is full of the sounds of innumerable creatures with whom they share their environment: forest elephants, monkeys, birds and frogs, all of whom speak their own languages. And fruit and nut trees are there in abundance, harvested by the little people whenever they are in season.</p>
                <p>The Pygmies, of course, are hunter gatherers. Traditionally they never cut down trees to create gardens or farmland. In their ceremonies they sing the praises of the forest, the game animals, the berries and mushrooms, the sweet tasting roots and the honey bees. They also sing about the leopards that lurk among the trees. But both pygmies and leopards prefer to go their separate ways, respecting one another: The pygmies know about the leopard’s fearsome teeth and the leopards know about the pygmies bows and poison arrows.</p>
                <p>Pygmies have been studied more intensely than most tribal cultures. They have fascinated anthropologists because their ancient lifestyle is so very different from the adaptations of farming and city people – from a world to which most of us belong today. Their profound reverence for their forest home is vividly described by Colin Turnbull in his book ‘The Forest People’. He lived in them in the Ituri Forest in the Congo for three years and was accepted as their friend. He describes their hunting parties, their nomadic camps, their illnesses, quarrels and their love affairs.</p>
                <div className="image pygmyForestCamp"></div>
                <p className="figureText">A pygmy forest camp, Congo</p>
                <p>Turnbull is very eloquent in his descriptions: ‘What seems to other people to be eternal and depressing gloom, becomes a cool, restful, shady world. … If you have ears for it the forest is full of different sounds, exciting, mysterious, mournful, joyful. … There are a multitude of sounds … and the most joyful sound of all, to me, is the sound of the voices of the forest people as they sing a lusty chorus of praise to this wonderful world of theirs … it seems to come from all sides in sheer beauty and truth and goodness, full of the joy of living.’ </p>
                <p>Turnbull describes the Molimo ceremony – a festival in which the Pygmies sing elaborate, multi-tonal songs to celebrate the goodness of the forest. One interviewee told him: ‘The forest is a father and mother to us. It gives us all we need – food, clothing, shelter, warmth and affection. … If something big goes wrong it must be because the forest is sleeping. We wake it up by singing to it … then everything will be well and good again … we sing to the forest because we want it to share our happiness.’ </p>
                <p>All this stands in stark contrast to the attitudes of outsiders who are now clearing rainforests across the tropics for agricultural and urban development on an unprecedented scale.</p>
                <h4>From Africa to Amazonia</h4>
                <p>Central Africa’s Pygmy communities are not alone in their deep attachment to a forest world. Amazonia has many different forest cultures as well: tribes such as the Yanomami, Campa, Arara, Kayapo, Surui, Waorani and Assurini have all developed their own particular cultural adaptations to their local ecosystems. They, too, are long-standing forest dwellers, and they also gather food from the forest, and hunt and fish. But they are also forest farmers. They clear small patches of forest at a time to grow manioc roots and beans and bananas in the ash of their small-scale fires. As ‘slash-burn’ forest farmers, they remove only a little forest for their gardens each year, and after a few years they let the forest grow back, enriched with newly planted fruit and nut trees.</p>
                <div className="image ceremony"></div>
                <p className="figureText">A Kayapo ceremony, Brazil</p>
                <p>Amerindians have been forest dwellers for some 20 to 25,000 years, long enough to develop a profound understanding of their forest world, the most diverse ecosystems anywhere on earth, with thousands of different trees, and tens of thousands of bird, mammal and insect species. </p>
                <p>As forest cultures are studied by anthropologists and ethnobotanists, they are giving us a new understanding of the innate capabilities of the human mind. Once called primitives and uncivilised barbarians, they are finally being recognised as experts of some of the world's most complex ecosystems. They truly understand the ‘law of the jungle’, but it is not the cut-throat world that this term still implies: It is a world in which all living beings coexist in an immensely complex, interdependent web of life. And that includes the forest people themselves, the true guardians of this green world.</p>
                <p>I have spent many months in the Amazon forest, in Brazil and Peru, making TV documentaries and have had the opportunity to study the environmental knowledge of Amerindians, and it is truly astounding. The documentary film Jungle Pharmacy, which I produced for Channel 4 in 1988, is concerned with the Indians’ knowledge of the forest and its medicinal plants. There is no doubt that the Indians are great experts in the medicinal uses of plants. Ethno-biologists from European and American universities, who studied with shamans of various Amazonian tribes, found that they had a word for every plant in the forest and a use for most. And why not? They have been living in Amazonia long enough to experiment with the uses of innumerable plants for nutrition and medicine.</p>
                <p>Ethno-ecologist Darrell Posey pioneered an ecological understanding of Amazonian cultures, working for many years with the Kayapo in the Brazilian state of Para. In his book Kayapo ‘Ethnoecology and Culture’ he summarises his findings: ‘The Kayapo see the processes of the universe and life as cyclical. Plants, animals and people are all manifestations of the same dynamic energy. The Kayapo strive for a harmony with other elements of the universe, and sickness occurs when the balance of man and nature is upset. The overall balance between natural forces is maintained by performance of a complex cycle of rituals and ceremonies. These include singing and dancing and involve all Kayapo. Each individual is, therefore, directly involved in maintaining the harmony of life, and is an agent in the perpetuation of ecological stability and the well-being of self and society.’</p>
                <p>One does not need scientific instruments to learn about the world – we can utilise our highly developed senses and the capacity of our brains, and the knowledge that is accumulated is passed on from generation to generation. Amerindians have given the world a large number of plant remedies as well as valuable food crops such as maize, potatoes, avocados, tomatoes, papaya, manioc, bananas, beans and chilly. Without these plants our modern diet would be so much poorer. They have a great deal to teach us to help us utilise the forests’ biological resources intelligently. Could some of their remedies fight the many diseases afflicting the world today?</p>
                <p>The Amazonian tribes, like the pygmies, have a deep reverence for the forest they inhabit which they display in many intricate ceremonies. They tell us that they cannot survive without the forests which provide them with everything they need to live. These sentiments are eloquently  expressed by a leader of the Forest Peoples’ Alliance in Brazil, Elton Krenak:</p>
                <p>‘The forest holds a far greater meaning for us than the city holds for the people who live there. An urban person from Sao Paolo who wishes to live in Tokyo or New York simply migrates there. The forest’s sons and daughters do not migrate, they change places in accordance with the forest natural cycles such as rainy and dry seasons, but they would never want to leave their forest. All the knowledge of our forest people is based on a permanent relationship with the places in which they live. The people who live in one valley see rivers and mountains in a very different way from a geologist or a biologist. A mountain for us has a name, has children, has loves, has a history, has a story to tell because it is witnessed life passing. Our songs, the ceremonial songs, the healing songs, the war songs, were all gifts from the forest. They were given to us by the animals, the water, the wind: the author and composer was nature.’</p>
                <p>Similar statements come from a Waorani tribal leader, Nenquimote Nenquimo, a woman and mother. She says this:</p>
                <p>‘It took us thousands of years to get to know the Amazon rainforest, to understand her ways,  her secrets, to learn to survive and thrive with her. … My elders are my teachers. The forest is my teacher. … What I can say … has to do with thousands of years of love for this forest. Love in the deepest sense, as reverence. This forest has taught me to walk lightly, and because we have listened, learned ad defender her, she has given us everything: water, clean air, nourishment, shelter, medicines, happiness, meaning. And you are taking all this away, not just from us, but from everyone on this planet, and from future generations.’ </p>
                <p>These words could also have been those of forest people in Africa, Malaysia, Indonesia, the Philippines, or Papua New Guinea. They all share an extraordinary knowledge and a great love for their forest world. Yet this is difficult to understand for people who are not familiar with their forest world. The Bantu people on the verges of rainforests in the Congo, for instance, have been encroaching on these ecosystems to establish pastures and farmland for centuries. They are scared of the forest and don't know how to make their living from it.</p>
                <p>The same is true today of the cattle ranchers in Amazonia, who are now clearing forests there on an unprecedented scale, to supply expanding urban markets across the world. Over 20 per cent of the Amazon forests has been cleared in recent years to establish cattle ranches and soybean farms. The ranchers regard the forest as a green hell which they can't wait to get rid of to establish their facendas. Unfortunately, these ranches, established on fragile tropical forest soil, often stay fertile for only a few years, and where there was pristine rainforest there's only useless scrub now.</p>
                <p>Rainforest ways-of-life are now under threat as never before from logging, mining and agricultural development. And as more roads are built and more forest is burned by large-scale fires, the tribal people are exposed to deadly new. The advance of modern civilisation has taken a terrible toll on the Amazonian tribes: numbering six or seven million before contact after 1492, today only some 250,000 remain.</p>
                <h4>The food forests of the Chaga </h4>
                <div className="image farmstead"></div>
                <p className="figureText">A Chaga farmstead, Tanzania</p>
                <p>Tropical agroforestry systems – combining tree crops, annual food crops and animal husbandry – are another interesting topic to study. Across the tropics, agroforestry has been practised for hundreds or even thousands of years. This type of cultivation system has multiple benefits compared to single-crop cultivation, such as stable yields and farmer livelihoods, increased biodiversity, improved soil structure, minimal erosion and enhanced carbon sequestration. </p>
                <p>Some years ago, I visited and filmed the Chaga agroforestry communities on the slopes of Mount Kilimanjaro in Tanzania for the BBC. The Chaga originated from tribal groups such as the Masai in the plains of East Africa. Several hundred years ago they moved up into the lush forests on the southern slopes of Kilimanjaro where they developed a ‘multi-storey’ cultivation system, irrigated by mountain streams. They felled some of the trees, but maintained much of the forest cover, and under the newly open canopy they planted fruit and nut trees and banana and coffee bushes, with houses, huts and animal shelters scattered across the landscape. At ground level they cultivate vegetable crops such as millet, beans, taro and yam.</p>
                <p>The land use system of the Chaga involves management of light, shade, soil, water, trees, bushes, vegetables and animals. Cows, goats, pigs and chickens supply fertiliser for the food crops. Water from the glacier is shared out between farmers in a complex irrigation system. The forest trees provide the Chaga with timber for building houses and animal sheds. Initially their tree crops were African varieties, but more recently fruit and nut trees that originate from Asia and South America, such as mango, breadfruit and palm trees, were also introduced.  </p>
                <p>Today the Chaga grow most of Tanzania's export coffee in this complex multi-story cropping system. It contains nearly the same amount of biomass as the original tropical forests, but it also happens to support the highest human population density in East Africa: some 500 people per square kilometres. </p>
                <p>The cultivation system of the Chaga is one of the many variants of agroforestry or permaculture, probably the most promising approach to long-term food security for people throughout the tropics. Today agroforestry systems are once again being adopted to assure food security in third world countries.  </p>
                <h4>A Hearding Way of Life</h4>
                <div className="image herder"></div>
                <p className="figureText">Nuer cattle herder, Sudan</p>
                <p>I do not want to give the impression though, that it is only forest people or cultivators who have developed sustainable land use systems. One of the first major agro-ecological studies I the 
                1930s was about cattle herding people, the Nuer in the Sudan, some of whom still continue to practice their ancient way of life. This study, by the British anthropologist Edward Evans-Pritchard, showed how they, too, have long practised their own version of sustainable land use. They think that they live in the finest world’s finest land, even though outsiders would tend to see it as a monotonous savannah and marsh landscape. The land is certainly very suitable for cattle grazing, which the Nuer, and many other cattle herding people in Africa, have been conducting sustainably for thousands of years. These grasslands are much more suitable for grazing than for farming. People can't eat grass but cattle can and must. The milk the cows produce is the Nuer's primary source of food.</p>
                <p>Evans-Pritchard calls the relationship between the Nuer and the cattle symbiotic: ‘Cattle and men sustain life by the reciprocal services to one another. In this intimate symbiotic relationship, men and beasts form a single community of the closest kind. The men wake about dawn in the midst of their cattle and sit contentedly watching them until milking is finished. They then either take them to pasture and spend the day watching them graze, driving them to water, composing songs about them, and bringing them back to camp. Or they remain in the kraal and drink the milk, make tethering cords and ornaments for them, water and in other ways care for their calves, clean the kraal, and dry the dung for fuel. When the cattle return in the evening, they tether each beast to its own peg with cords made from the skins of their dead companions. A man knows each animal of his herd and the herds of his neighbours and kinsman: its colour, the shape of its horns, its peculiarities, its history, its ancestry, and its progeny.’ </p>
                <p>The life of cattle herders like the Nuer is seasonal. Herds are driven across their extensive territories according to the seasonal availability of grass. Cattle are the great love of people like the Nuer or their neighbours the Dinka – they sing songs to their favourite cattle and they name their children after them. Children learn from infancy how to look after the animals, how to milk them and how to protect them from raiders or wild animals. To live without cows means to them to live in abject poverty. To own cattle means to be wealthy, and rich Nuer are wealthy even in comparison with many cattle ranchers in today's Amazon. But unlike these, Nuer have developed a stable cattle culture in harmony with their local environment. The same is true of Sami reindeer herders in Norway and Finland, the Yak herders of Tibet and Nepal, or the Alpaca herders of the Andes. Their ways of life represent a well-thought-out, sustainable relationship with their mountain pastures. </p>
                <h4>Alpine pastoralist cultures</h4>
                <p>The same could be said for cattle-raising cultures like those still to be found across the Alps. In the mountain regions of Switzerland, Bavaria, Austria and France, cattle raising has been practised for centuries, and the local culture revolved around it. As a child I spent many summers months in the Allgaeu mountains in Bavaria, witnessing the lifestyle of mountain farmers.</p>
                <p>In late spring, the cattle are herded from their winter homes in the valleys up to the herb-rich mountain pastures where they spend the summer months. The ringing sound of their bells is ever present. The cows’ milk is made into cheese. Each cow has its own name and is regarded as an individual in its own right. Lead cows have the largest bells and are given a degree of responsibility for the younger animals. The old cows know the mountain pastures best, and they know how to stop younger animals from grazing too close to dangerous rock cliffs.</p>
                <div className="image cattle"></div>
                <p className="figureText">The ‘Almabtrieb’ – decorated cows being herded down from the mountains, Austria</p>
                <p>The mountain farmers know how to live sustainably. They maintain the fertility of the Alpine pastureland. They collect the manure accumulated in the lowland cowsheds during the winter months and return it to the grassland. They follow carefully established rules for keeping the steepest slopes covered in forest to prevent soil erosion. And the huge farmhouses in the valley, with cowsheds attached, are built of timber from those hillsides, whilst using the trees also a source of firewood.</p>
                <p>In the autumn, when the grass stops growing on the mountain pastures, the cows are taken down back into the valley. Their horns are decorated with ornate garlands, and the premier cows who carry the largest bells take the lead in the great procession down from the mountain pastures. I have witnessed the arrival of such herds in their home villages. The cows know the farmhouses and stables to which they belong and they go straight back to them to spend the winter months. The arrival of the cows back in the villages is followed by exuberant celebrations with dancing, singing, eating and drinking in local bars and restaurants. With a sense of cultural purpose, the whole village comes together in an exuberant affirmation of a shared lifestyle. Today such celebrations have become well publicised spectacles all over the Alps, increasingly aimed at tourists and their money.</p>
                <h4>Asian peasant farmers</h4>
                <p>Traditional peasant farmers, too, have a well-established concept of trans-generational continuity, and their cultures reflect this. If they have a chance to evolve over long periods of time, they will tend to develop an ever-stronger sense of cultural purpose. In many parts of the world, small-scale farming is still the dominant way of life, if not disrupted by rapid population growth or loss of fertile land or land accumulation by outside interests. Farming, by definition, is an artificial activity – establishing the human presence on specific pieces of land to the detriment of other living species. But well-thought-out farming systems can do much to assure long-term environmental stability.</p>
                <p>In his book ‘Farmers of 40 centuries’ Franklin Hiram King describes organic farming practices in China, Korea and Japan, and how they were based on a sustainable give-and-take between farming families and the land the utilised. Traditional practices included legume crops for nitrogen fixation, crop rotations and intercropping, terracing and the use of diverse crop varieties. Human, animal and crop wastes were systematically recycled to maintain soil fertility. </p>
                <p>In his introduction to be book he says this: ‘Almost every foot of land is made to contribute material for food, fuel or fabric. Everything which can be made edible serves as food for man or domestic animals. Whatever cannot be eaten or worn is used for fuel. The wastes of the body, of fuel and of fabric worn beyond other use are taken back to the field; before doing so they are housed against waste from weather, compounded with intelligence and forethought and patiently labored with through one, three or even six months, to bring them into the most efficient form to serve as manure for the soil or as feed for the crop. It seems to be a golden rule … that whenever an extra hour or day of labor can promise even a little larger return then that shall be given, and neither a rainy day nor the hottest sunshine shall be permitted to cancel the obligation or defer its execution.’ </p>
                <div className="image riceHarvest"></div>
                <p className="figureText">Rice harvest in China</p>
                <p>As King points out, this meticulous system of farming, based on nutrient and carbon cycling has maintained soil fertility and structure over forty centuries are more, and would have continued to do so if it had been maintained in contemporary China.</p>
                <h4>Human settlements and their hinterland</h4>
                <p>Similar cultivation systems have been established all over the world, not least in Spain and Portugal. A British geographer, Philip White, studied long-established cultivation patterns he found in many parts of Spain. He found intricate arrangements for different crops around village locations arranged in concentric circles: ‘On a terrain which is almost homogeneous, the pattern of settlement and land use is remarkably geometric. In the middle of the plain there are enormous villages. Around the villages, the fields and gardens on the one hand, and the olives and vines on the other, are disposed in concentric circles. The latter crops generally are found nearer the village than three or four kilometres from the village. There is a third ring, the uncleared land abutting on the surrounding hill, devoted to grain and tree crops according to the needs of the population and the state of the market.’</p>
                <p>Each of the crop circles surrounding the villages has different needs of cultivation and protection. Those closest to the village are most in need of regular manuring. They also tend to be the most valuable crops. Further away from the village, the vines, olives and fruit trees need attention only on a seasonal basis: in the spring and during harvest time in the summer and autumn. The grazing lands for sheep and goats are looked after by shepherds.</p>
                <p>This kind of land use system corresponds closely with the arrangements observed In East Germany by geographer Heinrich von Thuenen and described in his book ‘The Isolated State’, published in 1826, just before the industrial revolution changed the world. He describes how  traditional towns and villages, in the absence of major transport systems, utilised the land in the surrounding countryside. He described these arrangements as a function of two primary factors: the cost of transporting crops to market, and the land rent farmers could afford to pay.</p>
                <p>Von Thuenen’s model encompasses four concentric rings of land use surrounding a traditional European village or town. Horticulture and dairying lies in the first ring closest to the city since vegetables, fruit, milk and other dairy products must get to market quickly. The second ring is for timber and firewood, heavy materials that are difficult to transport over longer distances. The third ring consists of extensive fields crops, such as grain, that last longer than vegetables or dairy products. Ranching and rough grazing is located in the final, most distant ring. Beyond this fourth ring lies wilderness, which is too great a distance from the central town for any type of agricultural activity.</p>
                <div className="image town"></div>
                <p className="figureText">The town of Aachen in the 18th century surrounded by food crops </p>
                <p>It is important, then, to realise that a sustainable relationship between settlements and local landscapes assured stable cultures which, in one way or another, existed for eons of time.</p>
                <h4>Looking ahead</h4>
                <p>In this essay I draw attention to traditional lifestyles and their cultural contexts because they would seem to be successful adaptations to local ecological realities. Over time, people have developed a tremendous variety of land use systems. These were reinforced by daily feedback with the local environment, enabling people to regularly assess the impacts they were having, and to take necessary measures to ensure continuity. All the varied cultures described here maintained a meticulous give-and-take between themselves and their local landscapes, reinforced by ceremonies, myths, stories and educational practices, helping children to partake in the established lifestyle.</p>
                <p>What can we learn today from such practices? Faced with a global environmental crisis, it seems to me that it is important to try and understand the depth of the land use knowledge that people have accumulated over eons of time at the time.</p>
                <p>A modern agribusiness farmer is a very different ‘creature’ compared with a hunter-gatherer or small-scale cultivator. For instance, a Chinese peasant, on a few acres of land, would grow small quantities of food, mainly rice and vegetables, for his family’s needs, plus a surplus for sale. On his farmstead, all human and animal waste would be recycled and, eventually, returned the soil. In this close-loop system, he would only use human and animal muscle power, maintained by the food crops grown on his land. This ‘circular metabolism’ enabled him to keep his land fertile generation after generation, assuring continuity of family and community life.</p>
                <p>A modern wheat farmer in Kansas, or a soybean tycoon in Mato Grosso, by contrast, grows his crops on thousands or even tens of thousands of acres to supply distant markets. To keep his production system going, he uses a huge arsenal of farm machinery, pesticides and fertilisers. A large proportion of his crop has to be grown just to pay for all this technology. Soil productivity is not maintained by recycling organic waste but by applications of mineral fertilisers, a substantial proportion of which end up polluting groundwater, and nearby streams and rivers. Pesticides are used to counteract environmental imbalances inherent in monoculture. They, too, end up as pollutants of soil and water. Farm machinery, fertilisers and pesticides all originate from factories in some distant city, requiring large amounts of raw materials and energy in their manufacture.</p>
                <p>It is obvious that the environmental impact of our agribusiness farmer is vastly greater than that of our Chinese peasant. To make this kind of extractive system, which has little concern for   long-term soil fertility, a model for farmers in developing countries would be to prejudice any chance of global sustainability. And yet, this is the preferred model of economic planners the world over, replacing the concept of continuity with that of never-ending progress. But now the instabilities we are causing as a result of soil and resource depletion, pollution and environmental damage are staring us in the face.</p>
                <p>Whilst a whopping 70 per cent of the world’s farmland is still managed by small-scale family farms, rural peoples' access to land is under attack across the world. From Honduras to Kenya, from Palestine to the Philippines, from Brazil to Malaysia, people are being brutally dislodged from their forests, orchards, farms and villages. There have been agrarian strikes and protests in many places – by community leaders in Madagascar and Brazil, by landless folk in India and  Andalusia – the list of actions goes on and on. But despite this, land ownership is becoming more and more concentrated in the hands of the rich and powerful. </p>
                <p></p>
                <div className="image soybeans"></div>
                <p className="figureText">Soybean agribusiness in Mato Grosso, Brazil </p>
                <p>To supply ever-expanding global urban markets, sustainable land use systems are rapidly being superseded by agribusiness. But in the face of the global environmental calamity upon us, we should assure that traditional land use systems are not forgotten. Can we find ways to translate such cultural adaptations to natural environments into plausible contemporary modes? There are some promising trends: A vigorous organic movement in Europe and America, and the application of ‘permaculture’ ideas in experimental projects across the world, would seem to be particularly important at this point in time. </p>
                <p>A key point to make about the various ecological cultures I have described is that they draw their sustenance and energy supply from the living planet rather than from subterranean resources. Unprecedented access to fossil fuels has profoundly changed our relationship to the world around us. With oil available to us as never before, food today tends to come to our tables from distant places. For instance, in Germany or Britain today most of the fruit and vegetables we eat comes from Spain or Italy rather than from local fields. Animal feeds and bread wheat originate from faraway places such as Iowa, Canada or Brazil. Much of the timber we use is sourced by cutting down the forests that Pygmies or Amerindians call home.</p>
                <p>In our daily lives all of us are contributing to environmental destruction on a global scale. To counter this, we need to find ways to better utilise global communications and turn them into a global nervous system capable of swiftly informing us of any damage being done to the earth, and to enable us to respond to this with appropriate collective measures. Underpinning this is the need for a clear scientific understanding of what we can and what we can’t do to the earth if we wish life to preserve life in all its varied forms.</p>
                <p>To develop contemporary concepts of sustainable living requires us to think beyond economics, and to activate deeper layers of human consciousness represented by the broader concept of culture. We owe it to life on earth and to future generations to act vigorously in worldwide pollution prevention, conservation work and environmental regeneration. Learning from traditional practices can help us develop a new sense of continuity. We are nothing if not custodians of our home planet and all life upon it, and we’d better live up to that responsibility.</p>

            </div>
        )
	}
};

export default CultureAndEcology