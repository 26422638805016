import React from 'react';
import './Lectures.scss';

class Lectures extends React.Component {
	render() {
        return (
            <div className="lecturesBackgroundImage">
                <p className="lecturesDescription">
                </p>
            </div>
        )
	}
};

export default Lectures