import React from 'react';
import './Footer.scss';

class Footer extends React.Component {
	render() {
        return (
            <div className="footer">
                <br></br>
                <br></br>
            </div>
        )
	}
};

export default Footer